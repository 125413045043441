import { useTranslation } from 'react-i18next';
import IconLogo from './logo.svg';
import { getCurrentUser, logout } from '@lagoagest-lda/common-frontend-authentication';
import { MdOutlineEditNote, MdOutlineInventory, MdOutlineInventory2, MdOutlinePriceChange, MdOutlinePublishedWithChanges } from 'react-icons/md';
/*import { boxShadow } from 'html2canvas/dist/types/css/property-descriptors/box-shadow';*/

export const navbarData = {
    backgroundColor: '#fff',
    backgroundTopColor: '#ffffff00',
    boxShadow: 'rgba(51, 51, 51, 0.3) 0px 0px 5px',  // Use the box-shadow value directly as a string
    getCurrentUser: getCurrentUser,
    logo: IconLogo,
    logout: logout,
    title: "Neutri<span class='navbar-text-custom'>puro</span>",
    languages: ['en', 'pt']
}

export const footerData = {
    address: 'Rua Pedro Nunes, Nº 101, Zona Industrial. 2500-303 Caldas da Rainha',
    email: 'geral@neutripuro.com',
    facebookLink: 'https://www.facebook.com/Neutripuro-Lavandaria-Industrial-1832849606963543/',
    googleMapsLink: '//www.google.pt/maps/search/Rua+Pedro+Nunes,+N%C2%BA+101,+Zona+Industrial.+2500-303+Caldas+da+Rainha/@39.426239,-9.1630535,17z/data=!3m1!4b1?hl=pt-PT',
    //instagramLink: 'tbd',
    logo: IconLogo,
    phoneNumber: '262 889 020',
    title: "Neutri<span class='navbar-text-custom'>puro</span>",
    //twitterLink: 'tbd'
};

const GetItems = () => {
    const { t } = useTranslation();
    return [
        {
            icon: <MdOutlinePriceChange />,
            label: t('module.renting.customerPrice.header'),
            section: 'customer/price'
        },
        {
            child: [
                {
                    icon: <MdOutlineInventory />,
                    label: t('module.renting.stockCurrent.header'),
                    section: 'stock/current'
                },
                {
                    icon: <MdOutlinePublishedWithChanges />,
                    label: t('module.renting.stockMovement.header'),
                    section: 'stock/movement'
                },
                {
                    icon: <MdOutlineEditNote />,
                    label: t('module.renting.stockManagement.header'),
                    section: 'stock/management'
                }
            ],
            icon: <MdOutlineInventory2 />,
            label: 'Stock'
        }
    ];
}

//export const sidebarItems = GetItems();

export const sidebarData = {
    //collapsable: false,
    hasSettings: true,
    hasProfile: true,
    getCurrentUser: getCurrentUser,
    items: GetItems,
    logout: logout
    //title: 'tbd'
}