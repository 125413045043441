import React from 'react';

import { useTranslation } from 'react-i18next';

import { MdOutlineEditNote, MdOutlineInventory, MdOutlineInventory2, MdOutlinePriceChange, MdOutlinePublishedWithChanges } from 'react-icons/md';
import { navbarData, sidebarData } from '../data';

import { Page, Profile } from '@lagoagest-lda/common-frontend-core';
import { CustomerPriceCard, StockCurrentCard, StockManagementCard, StockMovementCard } from '@lagoagest-lda/module-renting-frontend';

const ProfilePage = () => {
    const { t } = useTranslation();

    // <StockManagementCard path='stock/management' />
    const pageContent = (
        <div className='page-content'>
            <div className='function-cards'> 
                <StockCurrentCard path='stock/current' />
                <StockManagementCard path='stock/management' color='#47ab42' />
                <StockMovementCard path='stock/movement' color='#47ab42' />
                <CustomerPriceCard path='customer/price' />
            </div>
        </div>);


    const sidebarItems = [
        {
            icon: <MdOutlinePriceChange />,
            label: t('module.renting.customerPrice.header'),
            section: 'customer/price'
        },
        {
            child: [
                {
                    icon: <MdOutlineInventory />,
                    label: t('module.renting.stockCurrent.header'),
                    section: 'stock/current'
                },
                {
                    icon: <MdOutlinePublishedWithChanges />,
                    label: t('module.renting.stockMovement.header'),
                    section: 'stock/movement'
                },
                {
                    icon: <MdOutlineEditNote/>,
                    label: t('module.renting.stockManagement.header'),
                    section: 'stock/management'
                }
            ],
            icon: <MdOutlineInventory2 />,
            label: 'Stock'
        }
    ];

    const sidebar = {
        //collapsable: false,
        hasSettings: true,
        hasProfile: true,
        getCurrentUser: navbarData.getCurrentUser,
        items: sidebarItems,
        logout: navbarData.logout
        //title: 'tbd'
    }

    return <Page content={pageContent} logout={navbarData.logout} navbar={navbarData} sidebar={sidebar} />;
};

export default ProfilePage;