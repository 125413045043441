import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { FloorPlanner } from '@lagoagest-lda/module-floorplanner-frontend';
import { Navbar, Footer } from '@lagoagest-lda/common-frontend-core';
import { navbarData, footerData } from '../../data'; // Import navbar data

const FloorPlannerPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar 
        minimal={true} 
        backgroundColor={navbarData.backgroundColor} 
        backgroundTopColor={navbarData.backgroundTopColor} 
        title={navbarData.title} 
        logo={navbarData.logo}
        isLoginVisible={!!navbarData.getCurrentUser()} // Check if user is logged in
        getCurrentUser={navbarData.getCurrentUser} // Pass getCurrentUser function from navbarData
        logout={navbarData.logout} // Use the logout function
        profile={() => navigate('/profile')} 
        languages={navbarData.languages} // Pass languages for language selection
        settings={() => navigate('/settings')}
      />
      <FloorPlanner />
      <Footer minimal={true} logo={footerData.logo} title={footerData.title} />
    </>
  );
};

export default FloorPlannerPage;
