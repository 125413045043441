import React, { useMemo, useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// Sample JSON data (replace this with your actual data import)
const accumulatedData = [
  {
    "articleCode": "NP.AL.LNCL.PQ000",
    "articleDescription": "Lençol Pequeno",
    "details": [
      {
        "date": [2024, 8, 20],
        "inputQuantity": 1,
        "outputQuantity": 1,
        "orderedQuantity": 0,
        "pendingQuantity": -1
      },
      {
        "date": [2024, 8, 21],
        "inputQuantity": 2,
        "outputQuantity": 0,
        "orderedQuantity": 0,
        "pendingQuantity": 5
      },
      {
        "date": [2024, 8, 22],
        "inputQuantity": 0,
        "outputQuantity": 3,
        "orderedQuantity": 0,
        "pendingQuantity": -1
      },
      // ...other entries
    ]
  }
];

const locales = { 'en-US': enUS };
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales,
});

const Accumulated: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<{ title: string; quantity: number } | null>(null);

  const events = useMemo(() => {
    const formattedEvents: Array<{ title: string; start: Date; end: Date; allDay: boolean; quantity: number }> = [];

    accumulatedData.forEach(article => {
      article.details.forEach(detail => {
        const eventDate = new Date(detail.date[0], detail.date[1] - 1, detail.date[2]); // Create a Date object

        if (detail.inputQuantity > 0) {
          formattedEvents.push({
            title: `Entry: ${detail.inputQuantity}`,
            start: eventDate,
            end: eventDate,
            allDay: true,
            quantity: detail.inputQuantity // Store the quantity for the modal
          });
        }

        if (detail.outputQuantity > 0) {
          formattedEvents.push({
            title: `Exit: ${detail.outputQuantity}`,
            start: eventDate,
            end: eventDate,
            allDay: true,
            quantity: detail.outputQuantity // Store the quantity for the modal
          });
        }
      });
    });

    return formattedEvents;
  }, []);

  const handleSelectEvent = (event: any) => {
    setSelectedEvent({ title: event.title, quantity: event.quantity });
    setShowModal(true); // Show the modal when an event is clicked
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };

  return (
    <div style={{ height: '500px', margin: '50px' }}>
      <h2>Accumulated Stock Calendar</h2>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        defaultView="month"
        views={['month', 'week', 'day']}
        toolbar={true}
        popup={true}
        onSelectEvent={handleSelectEvent} // Handle event click
      />

      {/* Modal to display event details */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedEvent?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Quantity: {selectedEvent?.quantity}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Accumulated;
