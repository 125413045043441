import React from 'react';
import { navbarData, footerData } from '../../data';
import { Navbar, Footer } from '@lagoagest-lda/common-frontend-core';
import './inov2030.css';
import banner from './inov2030.png';
import header from './inov2030.jpg';

const Inov2030 = () => {
    return (
        <>
            <Navbar minimal={true} backgroundColor={navbarData.backgroundColor} backgroundTopColor={navbarData.backgroundTopColor} title={navbarData.title} logo={navbarData.logo}/>
            <iframe src="/Neutripuro_INOV-PRD_6193_FichaProjeto.pdf" width="100%" height="1000px" />
            <Footer minimal={true} logo={footerData.logo} title={footerData.title}/>
        </>
    );
};

export default Inov2030;